<template>
  <b-container fluid>
      <h1>Connections</h1>
    <b-row class="mt-5">
      <b-col class="sm-4 text-center">
          <h3 class="mb-3">Máy in</h3>
          <b-row class="text-center" align-h="center">
            <b-col sm="3" class="mr-5" >
              <h4>Máy 1 </h4>
              <div class="mb-5"  style="text-align:center">
                <b-icon v-if="printer1" icon="check-circle-fill" variant="success"></b-icon>
                <b-icon v-else icon="exclamation-circle" variant="danger"></b-icon>
              </div>
            </b-col>
          
          <b-col sm="3">
            <h4>Máy 2 </h4>
            <div class="mb-5"  style="text-align:center">
              <b-icon v-if="printer2" icon="check-circle-fill" variant="success"></b-icon>
              <b-icon v-else icon="exclamation-circle" variant="danger"></b-icon>
            </div>
            
          </b-col>
          </b-row>
          <b-btn @click="getPrinterStatus">Kiểm tra</b-btn>
      </b-col>
      <b-col class="sm-4 text-center">
          <h3 class="mb-5">Cảm biến</h3>
          <div class="mb-5" style="text-align:center">
            <b-icon v-if="plc" icon="check-circle-fill" variant="success"></b-icon>
            <b-icon v-else icon="exclamation-circle" variant="danger"></b-icon>
          </div>
          <b-btn @click="getPLCStatus">Kiểm tra</b-btn>
          
      </b-col>
      <b-col class="sm-4 text-center">
          <h3 class="mb-5">Đếm</h3>
          <div class="mb-5" style="text-align:center">
            <h1 class="count">{{count}}</h1>
          </div>
          <b-btn @click="getSensorCount">Cập nhật</b-btn>
      </b-col>
      
    </b-row>
    
  </b-container>
</template>

<script>
import { checkPrinter,checkPLC,getSensor} from "../api/printerServices";
// import {createNamespacedHelpers} from "vuex"
// const AlertHelpers = createNamespacedHelpers("Alert");
export default {
  name: "Connections",
  data(){
    return {
      count: 0,
      plc: false,
      printer1: false,
      printer2: false,

    }
  },
  methods: {
    // ...AlertHelpers.mapActions(""),
    getSensorCount(){
      getSensor().then(res =>
      {
        if (res.data)
        this.count = res.data.count;
        else alert("Đã có lỗi xảy ra");
      });
    },
    getPLCStatus(){
      checkPLC().then(res => {
        if (res.data && res.data.status == "Connected")
        {
          this.plc = true;
        }
        else this.plc = false;
      })
    },
    getPrinterStatus(){
      checkPrinter().then(res=> {
        if (res.data)
        {
          if (res.data.status1=="Ready")
            this.printer1=true;
          else this.printer1 = false;
          if (res.data.status2=="Ready")
            this.printer2=true;
          else this.printer2=false;
        }
      })
    }
  }
};
</script>

<style scoped>
.b-icon {
  font-size: 5em;
}

.count{
  font-size: 4.3em;
}
</style>
